.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  background: white;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
  padding: 16px;
  outline: none;
  border-radius: 8px;
}

@media screen and (min-width: 40rem) {
  .container {
    width: 50%;
  }
}
