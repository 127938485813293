.messageRowContainerSent {
  display: flex;
  justify-content: end;
}

.messageRowContainerReceived {
  display: flex;
  justify-content: start;
}

.boxContainer {
  width: 60%;
  margin: 0.6rem 0;
}
