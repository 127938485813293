.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background: white;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
  outline: none;
  border-radius: 8px;
}

.top {
  display: flex;
  justify-content: end;
}

.modalContent {
  display: flex;
  padding: 1.4rem 1.4rem 4rem 1.4rem;
}

.title {
  margin: 0 0 1rem 0;
  font-weight: 600;
  text-align: center;
  font-size: 1.3rem;
}

.description {
  text-align: justify;
  padding: 0 2rem;
}

.btnContainer {
  margin: 2rem 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 40rem) {
  .container {
    width: 50%;
    padding: 0.5srem 0.5rem 0 0;
  }

  .modalContent {
    display: flex;
    padding: 2rem;
  }

  .textContainer {
    margin: 0 0 0 1rem;
  }
}
