.container {
  display: flex;
}

.mainContent {
  width: 100%;
}

.top {
  display: flex;
  justify-content: start;
  margin: 1rem 0 2rem 0;
  padding: 0 0 0 1rem;
}

.title {
  font-size: 2.5rem;
}

.postDate {
  color: #495057;
  font-size: 0.8rem;
}

.cardsSectionContainer {
  display: flex;
  flex-direction: column;
}

.contentCard {
  margin: 0.7rem 1.1rem;
  padding: 1.3rem;
  min-height: 6rem;
  border-radius: 25px;
}

.cardTitle {
  display: flex;
  margin: 0 0 2rem 0;
}

.cardTitleText {
  margin: 0 0 0 0.5rem;
  font-weight: 600;
  font-size: 1rem;
}

.projetFounderAttribute {
  margin: 2rem 0 0 0;
}

.projetFounderAttributeName {
  font-weight: 600;
  margin: 0 0 0.3rem 0;
}

.contactBtnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.6rem 0 1.3rem 0;
}

.contactBtnIcon {
  margin: 0 0.7rem 0 0;
}

.projectDescriptionTextContainer {
  padding: 0.5rem;
  word-break: break-all;
}

.openPositionCardsTitle {
  font-weight: 600;
  margin: 0 0 0.5rem 0;
  font-size: 0.8rem;
}

.openPositionsCardContainer {
  margin: 1.5rem 0;
}

.openPostionCardTextContainer {
  margin: 0 0 0 0.8rem;
}

.openPositionCardsDescription {
  word-break: break-all;
}

.openPositionsCardRole {
  display: flex;
  align-items: center;
  margin: 0 0 0 0.5rem;
}

.stakeChip {
  margin: 0.2rem;
}

.noDataInformationBox {
  display: flex;
  align-items: center;
  color: #868e96;
}

.noDataInformationText {
  margin: 0 0 0 0.5rem;
}

@media screen and (min-width: 40rem) {
  .cardsSectionContainer {
    justify-content: center;
    flex-direction: row;
  }

  .cardsSection {
    width: 33%;
  }

  .contentCard {
    margin: 2rem 1.1rem;
  }
}
