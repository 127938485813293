.container {
  display: flex;
}

.mainContentWithNavBar {
  width: 100%;
}

.mainContent {
  display: flex;
  justify-content: center;
}

.inputLabel {
  margin: 1rem 0 0.5rem 0;
  font-weight: 600;
  font-size: 0.8rem;
  color: #495057;
}

.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 0 0;
}
