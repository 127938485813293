.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.filterBtnContainer {
  display: flex;
}

.filterBtn {
  margin: 0 2rem 0 0;
}

.btnText {
  margin: 0 0 0 0.5rem;
  font-size: 0.8rem;
}

@media screen and (min-width: 40rem) {
  .container {
    padding: 1rem 1rem 1rem 2rem;
  }

  .btnText {
    margin: 0 0 0 0.5rem;
    font-size: 1rem;
  }
}
