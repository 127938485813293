.container {
  display: flex;
  padding: 0.3rem;
}

.containerSent {
  justify-content: end;
}

.containerReceived {
  justify-content: start;
}

.boxContainer {
  width: 60%;
  padding: 1rem;
  border: solid #868e96 0.01px;
  border-radius: 10px;
  position: relative;
}

.boxContainerSent {
  background: #ebfbee;
}

.boxContainerReceived {
  background: white;
}

.contentContainer {
  word-wrap: break-word;
}

.timeContainer {
  font-size: 0.8rem;
  color: #ced4da;
  display: flex;
  justify-content: end;
}
