.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filtersSkeletonContainer {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.projectsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 95%;
}

.projectSkeletonContainer {
  height: 11rem;
  width: 46%;
  margin: 0.8rem;
}
