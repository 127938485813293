.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background: white;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
  outline: none;
  border-radius: 8px;
  padding: 2rem;
}

.descriptionInput {
  margin: 2rem 0 0 0;
}

.label {
  font-weight: 600;
  font-size: 1rem;
  color: #495057;
  margin: 0 0 0.5rem 0;
  display: flex;
}

.labelText {
  margin: 0 0 0 0.5rem;
}

@media screen and (min-width: 40rem) {
  .container {
    padding: 3rem;
    width: 40%;
  }
}
