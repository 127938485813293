.skeletonContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 100vh;
  align-items: center;
}

.sideBarSkeleton {
  width: 20%;
}

.mainContentSkeleton {
  width: 80%;
}
