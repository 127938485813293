.container {
  display: flex;
  height: 100vh;
}

.left {
  display: none;
}

.right {
  width: 100%;
  padding: 5rem 3rem;
}

.logoContainer {
  margin: 0 0 0 -0.5rem;
}

.subtitle {
  font-weight: 600;
  font-size: 2rem;
}

.argumentsContainer {
  margin: 12rem 0 0 0;
}

.argumentsTitle {
  font-weight: 600;
  font-size: 1.4rem;
  margin: 0 0 0.9rem 0;
}

.argument {
  display: flex;
  margin: 1rem 0;
}

.argumentText {
  margin: 0 0 0 0.5rem;
  display: flex;
  align-items: center;
}

.inputContainer {
  margin: 0 0 1rem 0;
}

.submitBtnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
}

.conjonctionWordContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.8rem 0;
}

.helpActionsButtonsContainer {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  cursor: pointer;
}

.helpActionBtn {
  text-decoration: none;
  display: flex;
  text-align: center;
}

.helpActionBtn a {
  text-decoration: none;
  display: flex;
  text-align: center;
  text-decoration: none;
}

@media screen and (min-width: 40rem) {
  .left {
    display: block;
    width: 50%;
    background: linear-gradient(to right bottom, #2980b9, #ffffff 45%);
    animation: gradient 15s ease infinite;
    padding: 5rem;
  }

  .right {
    width: 50%;
    padding: 15rem 6rem;
  }

  .helpActionsButtonsContainer {
    justify-content: space-around;
  }
}
