.container {
  cursor: pointer;
  margin: 1rem;
  height: 15rem;
}

.cardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.mainContent {
  height: 90%;
}

.topicsRow {
  margin: 1rem 0 1rem 0;
}

.topicChip {
  margin: 0.2rem 0.4rem;
}

.titleContainer {
  margin: 0 0 1.5rem 0;
  display: flex;
  justify-content: space-between;
}

.titleText {
  font-size: 1.8rem;
  font-weight: 600rem;
  color: #495057;
}

.description {
  margin: 1.4rem 0 0 0;
  color: #868e96;
  text-align: justify;
  word-wrap: break-word;
  font-size: 1.1rem;
}

.creationDate {
  display: flex;
  font-size: 0.8rem;
  align-items: center;
  justify-content: end;
  color: #868e96;
  height: 10%;
}

@media screen and (min-width: 40rem) {
  .container {
    cursor: pointer;
    margin: 1rem;
    height: 18.5rem;
  }
}
