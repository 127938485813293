.container {
  display: flex;
}

.mainContent {
  width: 100%;
}

.projectBoxesContainer {
  margin: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.projectBoxContainer {
  width: 100%;
}

.noResultInfoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
}

@media screen and (min-width: 40rem) {
  .projectBoxesContainer {
    flex-direction: row;
  }

  .projectBoxContainer {
    width: 50%;
  }
}
