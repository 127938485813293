.container {
  padding: 1rem;
}

.mainMessage {
  font-weight: 600;
  font-size: 1.3rem;
}

.subMessage {
  margin: 0.3rem 0 0 0;
  color: #adb5bd;
  font-weight: 600;
}
